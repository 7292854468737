/* eslint-disable no-unreachable */
(function() {
  "use strict";
  console.log(
    "%cDo not paste anything here unless told to by a certified source.\n",
    "font-size: 12pt"
  );
  let backtop = () => document.getElementsByClassName("back-to-top")[0];
  window.onload = function() {
    let preloader = document.getElementById("preloader");
    preloader.classList.add("finished");
    setTimeout(() => {
      preloader.remove();
      console.log("loaded");
    }, 300);
    let b = backtop();
    if (b) {
      b.onclick = function() {
        window.scrollTo(0, 0);
        return false;
      };
      window.onscroll();
    } else {
      setTimeout(() => {
        backtop().onclick = function() {
          window.scrollTo(0, 0);
          return false;
        };
        window.onscroll();
      }, 3000);
    }
  };

  window.onscroll = function() {
    let scroll = window.scrollY;
    let elem = backtop();
    if (!elem) return;
    if (scroll > 100) {
      elem.classList.add("scrolling");
      document.getElementById("header").classList.add("header-scrolled");
    } else {
      elem.classList.remove("scrolling");
      document.getElementById("header").classList.remove("header-scrolled");
    }
  };

  // jQuery counterUp (used in Whu Us section)
  //   $('[data-toggle="counter-up"]').counterUp({
  //     delay: 10,
  //     time: 1000
  //   });

  //   // Porfolio isotope and filter
  //   $(window).on('load', function () {
  //     var portfolioIsotope = $('.portfolio-container').isotope({
  //       itemSelector: '.portfolio-item'
  //     });
  //     $('#portfolio-flters li').on( 'click', function() {
  //       $("#portfolio-flters li").removeClass('filter-active');
  //       $(this).addClass('filter-active');

  //       portfolioIsotope.isotope({ filter: $(this).data('filter') });
  //     });
  //   });

  // Testimonials carousel (uses the Owl Carousel library)
  //   $(".testimonials-carousel").owlCarousel({
  //     autoplay: true,
  //     dots: true,
  //     loop: true,
  //     items: 1
  //   });
})();
