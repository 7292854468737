<template>
  <div>
    <section id="welcome" class="clearfix">
      <b-container>
        <b-row>
          <b-col md="6">
            <img
              :src="localStorage.info.landing.image"
              alt="Tomalex"
              class="img-fluid"
            />
          </b-col>

          <b-col md="6" class="welcome-info">
            <h2 v-html="localStorage.info.landing.slogan"></h2>
            <div>
              <router-link
                :to="'/' + localStorage.info.code + '/services'"
                class="btn-services"
                >Our Services</router-link
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <Worked
      v-if="localStorage.info.clients && localStorage.info.clients.length > 1"
    ></Worked>

    <About></About>
    <Why></Why>
  </div>
</template>
<script>
const Worked = () =>
  import(/* webpackChunkName: "clients" */ "@/components/Worked");

const About = () =>
  import(/* webpackChunkName: "about" */ "@/components/About");

const Why = () => import(/* webpackChunkName: "why" */ "@/components/Why");

export default {
  name: "Home",
  components: {
    Worked,
    About,
    Why,
  },
  mounted() {
    document.title = "Tomalex - Home";
  },
};
</script>
<style scoped lang="scss">
#welcome {
  background: linear-gradient(200deg, var(--main) 50%, var(--second));
  //background: url("../assets/images/intro-bg-transparent.png");
  background-size: cover;
  padding: 20vh 0 120px 0;

  .welcome-info {
    h2 {
      color: var(--second);
      margin-bottom: 40px;
      font-size: 48px;
      font-weight: 700;
    }

    .btn-get-started,
    .btn-services {
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 32px;
      border-radius: 50px;
      transition: 0.5s;
      margin: 0 20px 20px 0;
      color: var(--second);
    }

    .btn-get-started {
      background: var(--main);
      border: 2px solid var(--main);
      color: var(--second);

      &:hover {
        background: none;
        border-color: var(--second);
        color: var(--second);
      }
    }

    .btn-services {
      border: 2px solid var(--second);

      &:hover {
        background: var(--second);
        border-color: var(--main);
        color: var(--main);
      }
    }
  }
}
</style>
