import "/opt/build/repo/node_modules/core-js/modules/es.array.iterator.js";
import "/opt/build/repo/node_modules/core-js/modules/es.promise.js";
import "/opt/build/repo/node_modules/core-js/modules/es.object.assign.js";
import "/opt/build/repo/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import reactiveStorage from "vue-reactive-storage";
import App from "./App.vue";
import router from "./router"; // import "./registerServiceWorker";
// Custom vanilla manipulation

import "./assets/css/main.scss";
import "./assets/js/main.js"; // Get the information

import { english, polish } from "./info";
var info = {
  english: english,
  polish: polish
}; // Bootstrap framework custom select plugins

import { LayoutPlugin, ImagePlugin, CollapsePlugin } from "bootstrap-vue/src/index.js";
import "bootstrap/dist/css/bootstrap.min.css"; // Fontawesome lib

import { library } from "@fortawesome/fontawesome-svg-core"; // Solid icons

import { faHome, faChevronUp, faChevronDown, faCamera, faShoppingBag, faChartBar, faMapMarkerAlt, faPhone, faEnvelope, faBars, faTimes } from "@fortawesome/free-solid-svg-icons"; // Brands

import { faTwitter, faFacebook, faInstagram, faGooglePlus, faLinkedin } from "@fortawesome/free-brands-svg-icons"; // Component

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faChevronDown, faHome, faChevronUp, faFacebook, faTwitter, faFacebook, faInstagram, faGooglePlus, faLinkedin, faCamera, faShoppingBag, faChartBar, faMapMarkerAlt, faPhone, faEnvelope, faBars, faTimes); // Component Registering

Vue.component("fa-icon", FontAwesomeIcon);
Vue.use(LayoutPlugin);
Vue.use(ImagePlugin);
Vue.use(CollapsePlugin); // Global fixed variable
// Vue.mixin({
//   data: function() {
//     return {
//       get globalData() {
//         // TS made me do this, just, just let it be
//         return function(lang: string) {
//           let o = Object.entries(info).find(x => x[0] == lang);
//           if (!o) {
//             let o = [null, info["english"]];
//           }
//           if (!o || !o[1]) return;
//           return o[1];
//         };
//       }
//     };
//   }
// });

Vue.use(reactiveStorage, {
  all: info,
  info: {
    landing: {}
  }
});
Vue.config.productionTip = false;
new Vue({
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");