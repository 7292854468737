import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NProgress from "nprogress";
Vue.use(VueRouter);
var routes = [{
  path: "/",
  redirect: "/".concat(localStorage.info ? JSON.parse(localStorage.info).code : "en", "/")
}, {
  path: "/:lang/",
  name: "Home",
  component: Home
}, {
  path: "/:lang/services",
  name: "Services",
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    "../views/Services.vue");
  }
}, {
  path: "/:lang/contact",
  name: "Contact Us",
  component: function component() {
    return import(
    /* webpackChunkName: "contact" */
    "../views/Contact.vue");
  }
}, {
  path: "*",
  name: "error",
  component: function component() {
    return import(
    /* webpackChunkName: "contact" */
    "../views/Error.vue");
  }
}];

if (process.env.NODE_ENV == "development") {
  routes.push({
    path: "/all",
    name: "Components",
    component: function component() {
      return import(
      /* webpackChunkName: "dev" */
      "../views/All.vue");
    }
  });
}

var router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior: function scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});
NProgress.configure({
  easing: "ease",
  speed: 500
});
router.beforeResolve(function (to, from, next) {
  if (to.name) {
    NProgress.start();
  }

  next();
});
router.afterEach(function (to, from) {
  NProgress.done();
});
export default router;