<template>
  <div id="app">
    <Navbar></Navbar>
    <div id="height">
      <transition name="fade-custom" mode="out-in">
        <router-view />
      </transition>
    </div>
    <div id="preloader"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
const Footer = () => import("@/components/Footer");
export default {
  name: "home",
  components: {
    Navbar,
    Footer
  }
};
</script>
<style>
#height {
  transition: all 1s;
  min-height: 100vh;
}
.fade-custom-enter-active,
.fade-custom-leave-active {
  transition: all 0.5s !important;
}
.fade-custom-enter,
.fade-custom-leave-to {
  transition: height 0.5s;
  opacity: 0;
}
</style>
