var english = {
  code: "en",
  icon: require("./assets/images/english.png"),
  contact: {
    phone: "+48 22 630 60 60",
    email: "info@tomalex.eu",
    address: ["Al. Jerozolimskie 65/79, lok. 1111", "00-697 Warsaw"]
  },
  social: [// { name: "twitter", link: "#" },
    // { name: "facebook", link: "#" },
    // { name: "instagram", link: "#" },
    // { name: "google-plus", link: "#" },
    // { name: "linkedin", link: "#" }
  ],
  landing: {
    slogan: "Tomalex,<br />exports and imports<br />done right.",
    image: require("./assets/images/connect.svg")
  },
  footer: {
    description: "At Tomalex, we strive to deliver to you the best possible product. Our professional personnel ensure that all of your requests are met with the highest standard, and all of your solutions met the exact criteria of your purchase."
  },
  stats: {
    description: "Our company specialize in complex import and exportation situations. Our experienced team can help you navigate or establish an importation and exportation clause. We can ensure that you are delivered with the best possible product, and with your satisfaction being put first. ",
    messages: [{
      icon: "home",
      title: "Consultation Services",
      description: "We have consultation services in order to help you navigate the legal and logistical obstacles of exportation."
    }, {
      title: "Importation",
      icon: "shopping-bag",
      description: "Our company specializes in getting what you want, where you want."
    }, {
      title: "Exportation",
      icon: "camera",
      description: "We offer services to assist in exporting a product, using our team’s experience and connections."
    }],
    stats: [// {
      //   value: 252,
      //   unit: "Clients"
      // },
      // {
      //   value: "100,000",
      //   unit: "Emails"
      // },
      // {
      //   value: "32",
      //   unit: "Team members"
      // }
    ]
  },
  services: {
    description: "Our company offers a series of specialized services meant to provide a seamless experience.",
    services: [{
      title: "Consultation Services",
      accentColor: "#f50ae4",
      new_tab: true,
      icon: "home",
      description: "We have consultation services in order to help you navigate the legal and logistical obstacles of exportation."
    }, {
      title: "Exportation",
      icon: "camera",
      url: "/",
      accentColor: "#fa1632",
      description: "We offer services to assist in exporting a product, using our team’s experience and connections."
    }, {
      title: "Importation",
      icon: "shopping-bag",
      url: "/",
      accentColor: "#3bf29f",
      description: "Our company specializes in getting what you want, where you want. We have countless experiences employees ready at your disposal to meet your needs in services and products. As a whole, our company is capable to handling all requests, no matter the nature of the request itself."
    } // {
    //   title: "Lorem Ipsum 4",
    //   icon: "chart-bar",
    //   url: "/",
    //   accentColor: "#4c3ebf",
    //   description:
    //     "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident",
    // },
    ]
  },
  faq: {
    questions: [{
      question: "How much do our services cost?",
      body: "Like all of our customers, every scenario where our services are employed differs. As such, it would be inapposite to offer a qoute. You should contact us, at +48 22 630 60 60 or visit us at ul. Jozefa Bema 87 lok. 3U."
    }, {
      question: "What can we export or import?",
      body: "Our company specializes in importation and exportation, period. We offer our services to any product and any customer, and we strive to fullfil every request."
    }, {
      question: "Where do you offer your support?",
      body: "We are a company that is based solely in Poland, and specialize in Polish-Vietnamese intra-exportation, however, our services are not limited to either of the two countries."
    }],
    description: "Frequently Asked Questions regarding the services provided. If you have additional questions, please don't hesitate to contact us at +48 22 630 60 60 or email us at info@tomalex.eu"
  },
  about: {
    description: "An export and import consultation service made for Polish and Vietnamese customers",
    body: [{
      title: "We believe in Quality",
      image: require("./assets/images/list.svg"),
      body: ["At Tomalex, we strive to deliver to you the best possible product. Our professional personnel ensure that all of your requests are met with the highest standard, and all of your solutions met the exact criteria of your purchase.  ", "Our employees consider the quality of our work to be the epitome of our company. We pride ourselves in guaranteeing that you will be satisfied, and that we are delivering the exact solution that you need. Our team handles all domestic and international requests, and specialisms in maximizing quality while reducing costs. In Tomalex, Quality comes first. "]
    }, {
      title: "We believe in customer orientation",
      image: require("./assets/images/creative.svg"),
      body: ["For us, the requests of our customers are most important. We focus on prioritising the success of our clients, and ensuring that all of our clients’ requests are met to the highest standards. We pride ourselves in prioritizing our customers’ satisfaction. ", "Our team focuses on ensuring that our customers are happy, no matter what. Our mindset is that of the customers, and we do everything in our power to ensure that the customers’ wishes are fulfilled. "]
    }, {
      title: "We believe in international development",
      image: require("./assets/images/factory.svg"),
      body: ["Our team includes several experienced experts with legions of connections. We are able to cultivate, establish, and develop partnerships with several international enterprises. Our experience in industry and consumer goods allows us to meet the requirements of our customers. ", "As a company, we have experience dealing with all ranges of consumer requests. Our connections to established relationships allow us to import and export any customer request. Our experience allows us to maintain and efficiently navigate the legal maze of importing and exporting. "]
    }]
  },
  clients: [// {
    //   name: "Vue School",
    //   url: "https://tomalex.eu",
    //   image_url: require("./assets/images/vueschool.png")
    // },
    // {
    //   name: "Tidelift",
    //   url: "https://tomalex.eu",
    //   image_url: require("./assets/images/tidelift.png")
    // },
    // {
    //   name: "DevSquad",
    //   url: "https://tomalex.eu",
    //   image_url: require("./assets/images/devsquad.png")
    // }
  ]
};
var polish = {
  code: "pl",
  icon: require("./assets/images/polish.png"),
  contact: {
    phone: "+48 22 630 60 60",
    email: "info@tomalex.eu",
    address: ["Al. Jerozolimskie 65/79, lok. 1111", "00-697 Warsaw"]
  },
  social: [{
    name: "twitter",
    link: "#"
  }, {
    name: "facebook",
    link: "#"
  }, {
    name: "instagram",
    link: "#"
  }, {
    name: "google-plus",
    link: "#"
  }, {
    name: "linkedin",
    link: "#"
  }],
  landing: {
    slogan: "Tomalex,<br />polish exports and imports<br />done right.",
    image: require("./assets/images/connect.svg")
  },
  footer: {
    description: "Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue."
  },
  stats: {
    description: "Our company specialise in complex import and exportation situations. Our experienced team can help you navigate or establish an importation and exportation clause. We can ensure that you are delivered with the best possible product, and with your satisfaction being put first. ",
    messages: [{
      icon: "home",
      title: "Lorem ipsum",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }, {
      title: "Lorem ipsum",
      icon: "shopping-bag",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }, {
      title: "Lorem ipsum",
      icon: "camera",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }],
    stats: [//   {
      //     value: 252,
      //     unit: "Clients"
      //   },
      //   {
      //     value: "100,000",
      //     unit: "Emails"
      //   },
      //   {
      //     value: "32",
      //     unit: "Team members"
      //   }
    ]
  },
  services: {
    description: "Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.",
    services: [{
      title: "Lorem Ipsum 1",
      accentColor: "#f50ae4",
      url: "https://dancodes.online",
      new_tab: true,
      icon: "home",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }, {
      title: "Lorem Ipsum 2",
      icon: "camera",
      url: "/",
      accentColor: "#fa1632",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }, {
      title: "Lorem Ipsum 3",
      icon: "shopping-bag",
      url: "/",
      accentColor: "#3bf29f",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }, {
      title: "Lorem Ipsum 4",
      icon: "chart-bar",
      url: "/",
      accentColor: "#4c3ebf",
      description: "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
    }]
  },
  faq: {
    questions: [{
      question: "Why is Lorem ipsum so helpful?",
      body: "Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt."
    }, {
      question: "Is DanCodes the best coder ever?",
      body: "Yes, Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt."
    }],
    description: "Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto. Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia exercitationem nam accusantium provident quia."
  },
  about: {
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    body: [{
      title: "Lorem ipsum dolor sit amet.",
      image: require("./assets/images/list.svg"),
      body: ["Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt.", "Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto. Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia exercitationem nam accusantium provident quia."]
    }, {
      title: "Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.",
      image: require("./assets/images/creative.svg"),
      body: ["Ipsum in aspernatur ut possimus sint. Quia omnis est occaecati possimus ea. Quas molestiae perspiciatis occaecati qui rerum. Deleniti quod porro sed quisquam saepe. Numquam mollitia recusandae non ad at et a. Ad vitae recusandae odit possimus.", "Quaerat cum ipsum corrupti. Odit qui asperiores ea corporis deserunt veritatis quidem expedita perferendis. Qui rerum eligendi ex doloribus quia sit. Porro rerum eum eum."]
    }, {
      title: "Neque saepe temporibus repellat ea ipsum et. Id vel et quia tempora facere reprehenderit.",
      image: require("./assets/images/factory.svg"),
      body: ["Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt.", "Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto. Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia exercitationem nam accusantium provident quia."]
    }]
  }
};
export { english, polish };