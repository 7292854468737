<template>
  <header id="header" class="fixed-top">
    <b-container>
      <div class="logo float-left ml-3">
        <h1 class="text-light">
          <router-link :to="'/' + localStorage.info.code">
            <span>Tomalex</span>
          </router-link>
        </h1>
      </div>
      <button
        type="button"
        @click="overlay"
        class="mobile-nav-toggle d-lg-none"
      >
        <fa-icon v-if="!overlayed" icon="bars"></fa-icon>
        <fa-icon v-else icon="times"></fa-icon>
      </button>
      <div class="main-nav float-right d-none d-lg-block">
        <ul>
          <li v-for="route in $router.options.routes" :key="route.name">
            <router-link
              v-if="route.name != 'error'"
              :to="route.path.split(':lang').join(localStorage.info.code)"
              >{{ route.name }}</router-link
            >
          </li>
          <li
            v-for="lang in Object.entries(localStorage.all)"
            :key="lang[1].icon"
            @click="language(lang[0])"
          >
            <router-link :to="'/' + lang[1].code + '/'">
              <img
                :class="
                  'icon-lang' +
                    (localStorage.language == lang[0] ? ' current-lang' : '')
                "
                :alt="'Tomalex in ' + lang[0]"
                :src="lang[1].icon"
              />
            </router-link>
          </li>
        </ul>
        <!-- <li class="drop-down">
            <router-link to="">Drop Down</router-link>
            <ul>
              <li><router-link to="#">Drop Down 1</router-link></li>
              <li class="drop-down">
                <router-link to="#">Drop Down 2</router-link>
                <ul>
                  <li><router-link to="#">Deep Drop Down 1</router-link></li>
                  <li><router-link to="#">Deep Drop Down 2</router-link></li>
                  <li><router-link to="#">Deep Drop Down 3</router-link></li>
                  <li><router-link to="#">Deep Drop Down 4</router-link></li>
                  <li><router-link to="#">Deep Drop Down 5</router-link></li>
                </ul>
              </li>
              <li><router-link to="#">Drop Down 3</router-link></li>
              <li><router-link to="#">Drop Down 4</router-link></li>
              <li><router-link to="#">Drop Down 5</router-link></li>
            </ul>
        </li>-->
      </div>
    </b-container>
    <div class="mobile-nav-overlay"></div>
    <nav class="mobile-nav d-lg-none">
      <ul>
        <li
          v-for="route in $router.options.routes"
          @click="closeOverlay"
          :key="route.name"
        >
          <router-link
            v-if="route.name != 'error'"
            :to="route.path.split(':lang').join(localStorage.info.code)"
          >
            {{ route.name }}
          </router-link>
        </li>
        <li
          v-for="lang in Object.entries(localStorage.all)"
          :key="lang[1].icon"
          @click="language(lang[0])"
        >
          <router-link :to="'/' + lang[1].code + '/'">
            <img
              :class="
                'icon-lang' +
                  (localStorage.language == lang[0] ? ' current-lang' : '')
              "
              :src="lang[1].icon"
              :alt="'Tomalex in ' + lang[0]"
            />
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>
<style lang="scss" scoped>
.icon-lang {
  cursor: grab;
  border-radius: 0.5rem;
  filter: grayscale(1);
  transition: all 0.5s;
}
.current-lang {
  filter: grayscale(0) !important;
}
.main-nav {
  margin: 0;
  padding: 0;
  list-style: none;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }

  a {
    display: block;
    position: relative;
    color: var(--main);
    padding: 10px 15px;
    transition: 0.3s;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    &:hover,
    &.router-link-exact-active.router-link-active {
      color: var(--main);
      opacity: 0.7;
      text-decoration: none;
    }
  }

  .active > a,
  li:hover > a {
    color: var(--main);
    text-decoration: none;
  }

  .drop-down {
    ul {
      display: block;
      position: absolute;
      left: 0;
      top: calc(100% + 30px);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      background: var(--second);
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: ease all 0.3s;
    }

    &:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    li {
      min-width: 180px;
      position: relative;
    }

    ul {
      a {
        padding: 10px 20px;
        font-size: 13px;
        color: #004289;

        &:hover {
          color: var(--main);
        }
      }

      .active > a,
      li:hover > a {
        color: var(--main);
      }
    }

    > a:after {
      content: "\f107";
      font-family: FontAwesome;
      padding-left: 10px;
    }

    .drop-down {
      ul {
        top: 0;
        left: calc(100% - 30px);
      }

      &:hover > ul {
        opacity: 1;
        top: 0;
        left: 100%;
      }

      > a {
        padding-right: 35px;

        &:after {
          content: "\f105";
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -260px;
  width: 260px;
  padding-top: 18px;
  background: #7521214d;
  transition: 0.4s;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    opacity: 1;
    display: block;
    position: relative;
    color: var(--second);
    padding: 10px 20px;
    font-weight: 500;

    &:hover {
      color: #74b5fc;
      text-decoration: none;
    }
  }

  .active > a,
  li:hover > a {
    color: #74b5fc;
    text-decoration: none;
  }

  .drop-down > a:after {
    content: "\f078";
    font-family: FontAwesome;
    padding-left: 10px;
    position: absolute;
    right: 15px;
  }

  .active.drop-down > a:after {
    content: "\f077";
  }

  .drop-down {
    > a {
      padding-right: 35px;
    }

    ul {
      display: none;
      overflow: hidden;
    }

    li {
      padding-left: 20px;
    }
  }
}

.mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;

  svg {
    transition: all 0.5s;
    margin: 24px 18px 0 0;
    color: var(--main);
  }
}
.mobile-nav-active .mobile-nav-toggle svg {
  color: var(--second);
}

.mobile-nav-overlay {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: #ff40409c;
  overflow: hidden;
  display: none;
}

#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 20px 0;
  background: var(--second);

  &.header-scrolled,
  &.header-pages {
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
    height: 60px;
    padding: 10px 0;
    .mobile-nav-toggle {
      svg {
        margin: 14px 18px 0 0;
      }
    }
  }

  .logo {
    h1 {
      font-size: 36px;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 3px;
      text-transform: uppercase;

      a {
        color: var(--main);
        text-decoration: none;

        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }

    img {
      padding: 0;
      margin: 7px 0;
      max-height: 26px;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      overlayed: false,
    };
  },
  methods: {
    overlay() {
      let added = document.body.classList.toggle("mobile-nav-active");
      this.overlayed = added;
    },
    closeOverlay() {
      document.body.classList.remove("mobile-nav-active");
      this.overlayed = false;
    },
    language(lang) {
      console.log(lang);
      this.localStorage.language = lang;
      this.localStorage.info = this.localStorage.all[lang];
      if (!this.localStorage.info) return (window.location.href = "/");
      document
        .querySelector("html")
        .setAttribute("lang", lang == "polish" ? "pl" : "en");
    },
  },
  mounted() {
    this.$nextTick(() =>
      this.language(
        this.localStorage.language ||
          (this.$route.params.lang == "pl" ? "polish" : "english")
      )
    );
    console.log(this.localStorage.language);
  },
};
</script>
